.App {
  text-align: center;
}

.HeaderText {
  flex-grow: 1;
  text-align: left;
  margin-top: 0 !important;
}

.DropdownArrow { 
  font-size: 300px !important;
  fill: white !important;
  cursor: pointer !important;
}

.DropdownArrow:hover {
  fill: gray !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hr-lines{
  position: relative;
  margin: 100px auto;
  text-align: center;
}

.hr-lines:before{
  content:" ";
  height: 5px;
  width: var(--width);
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.hr-lines:after{
  content:" ";
  height: 5px;
  width: var(--width);
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}